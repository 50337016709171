body {
  background-color: #f0f2f5 !important; 
}

ul {
  padding-inline-start: 20px;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-dark {
  color: #222;
}

.sub-title-dark {
  color: #666;
}

.sub-title-light-dark {
  color: #9d9d9d;
}


.title-light {
  color: #fff;
}

.sub-title-light {
  color: #fff;
}

.disable {
  .title-dark {
    color: #aaa;
  }
  .sub-title-dark {
    color: #aaa;
  }
}

.button-container {
  button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-form-item label {
  margin-left: 8px;
  font-weight: 600;
  padding-left: 5px;
}

.ant-form-item-required::before {
  left: -5px;
  top: 2px;
  position: absolute;
}

.collapsed {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.word-wrap {
  // overflow: hidden;
  // text-overflow:ellipsis;
  white-space: break-spaces !important;
}

.break-all {
  word-break: break-all;
}

.box-shadow {
  border: 1px solid #efefef;
  box-shadow: 1px 1px 4px 1px #e7e7e7;
  border-radius: 4px;
}

.warning-slider .ant-slider-track {
  background-color: orange;
}

.warning-slider:hover .ant-slider-track {
  background-color: orange!important;
}

.safe-slider .ant-slider-track {
  background-color: green;
}

.safe-slider:hover .ant-slider-track {
  background-color: green!important;
}

.border-container {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 20px 40px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mx-1 {
  margin-left: 10px;
  margin-right: 10px;
}

.green-btn {
  background-color: #63B963 !important;
  border-color: #63B963 !important;
  color: #fff !important;
}

.green-btn:hover {
  background-color: #85cd85 !important;
  border-color: #85cd85 !important;
}

.d-flex {
  display: flex;
  align-items: center;
}